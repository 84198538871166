import { SEARCH_VERTICALS, KNOWN_SEARCH_VERTICALS } from 'constants/search'
import { useMemo } from 'react'
import { useLocation } from 'react-router'

const SEARCH_VERTICAL_VALUES = Object.values(SEARCH_VERTICALS).filter((searchVertical) => KNOWN_SEARCH_VERTICALS.has(searchVertical))

export default function useCurrentGlobalSearchVerticalPage() {
  const location = useLocation()
  const currentGlobalSearchVerticalPage = useMemo(() => SEARCH_VERTICAL_VALUES.find((searchVertical) => location.pathname.endsWith(searchVertical)) || undefined, [location.pathname])

  return currentGlobalSearchVerticalPage
}
