import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import config from 'constants/config'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import useGlobalSearchVerticalTabs from 'hooks/GlobalSearch/useGlobalSearchVerticalTabs'
import { searchNavCTAClick, searchNavCTAHover } from 'analytics/eventDefinitions'
import Clickable from 'components/Common/Clickable'
import { addGTMEvent } from 'api/googleTagManager'
import useGlobalSearchURLHashVertical from 'hooks/GlobalSearch/useGlobalSearchURLHashVertical'
import useCurrentGlobalSearchVerticalPage from 'hooks/GlobalSearch/useCurrentGlobalSearchVerticalPage'
import ProductNameFlipper from './ProductFlipper'
import Group from 'components/utils/Group'
import IconButton from 'components/Luxkit/Button/IconButton'

const SearchBar = styled(Clickable)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: ${rem(8)};
  margin: auto;
  overflow: hidden;
  white-space: nowrap;
  gap: ${rem(4)};
  box-shadow: ${props => props.theme.shadow.flat.small};
`

const SearchContainer = styled.div`
  display: flex;
  border: 1px solid ${props => props.theme.palette.neutral.default.five};
`

const StyledBodyText = styled(BodyText)`
  margin-left: ${rem(12)};
`

function SearchToggle() {
  const globalSearchVerticalTabs = useGlobalSearchVerticalTabs()

  const { toggleSearchVertical } = useGlobalSearchURLHashVertical()
  const currentSearchVerticalPage = useCurrentGlobalSearchVerticalPage()

  const handleSearchButtonClick = useCallback(() => {
    if (!currentSearchVerticalPage) {
      toggleSearchVertical([...globalSearchVerticalTabs.keys()][0])
    } else {
      toggleSearchVertical(currentSearchVerticalPage)
    }
    addGTMEvent(searchNavCTAClick())
  }, [currentSearchVerticalPage, globalSearchVerticalTabs, toggleSearchVertical])

  const handleSearchButtonMouseEnter = useCallback(function() {
    addGTMEvent(searchNavCTAHover())
  }, [])

  return (
    <SearchContainer>
      <SearchBar
        role="presentation"
        onClick={handleSearchButtonClick}
        onMouseEnter={handleSearchButtonMouseEnter}
      >
        <Group direction="horizontal" gap={4}>
          <StyledBodyText variant="small" colour="neutral-two">
            {config.header.searchBarText}
          </StyledBodyText>
          <ProductNameFlipper />
        </Group>
        <IconButton kind="primary" shape="square" nonInteractable size="small">
          <LineSearchIcon />
        </IconButton>
      </SearchBar>
    </SearchContainer>
  )
}

export default SearchToggle
