import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineBullseyeIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12 5a7 7 0 1 0 0 13.9999A7 7 0 0 0 12 5Zm0 12a5 5 0 1 1-.0001-10A5 5 0 0 1 12 17Zm0-8a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0 4a1 1 0 1 1-.0002-2A1 1 0 0 1 12 13Zm0-12a11 11 0 1 0 0 22 11 11 0 0 0 0-22Zm0 20a8.9996 8.9996 0 0 1-8.315-5.5558A9.0002 9.0002 0 0 1 15.4443 3.6851 8.9999 8.9999 0 0 1 21 12a9.0002 9.0002 0 0 1-9 9Z"/>
  </SvgIcon>
}

export default LineBullseyeIcon
