import React, { useMemo } from 'react'
import styled from 'styled-components'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  vOffset?: number | string;
}

const Anchor = styled.div`
  position: relative;
  left: 0;
`

const ScrollAnchor = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { vOffset, ...rest } = props
  const style = useMemo(() => ({ top: `-${vOffset}` }), [vOffset])

  return (
    <Anchor {...rest} style={style} ref={ref}/>
  )
})

ScrollAnchor.defaultProps = {
  vOffset: 0,
}

export default ScrollAnchor
