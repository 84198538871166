import BodyText from 'components/Luxkit/Typography/BodyText'
import useGlobalSearchVerticalTabs from 'hooks/GlobalSearch/useGlobalSearchVerticalTabs'
import { rem } from 'polished'
import React, { useMemo } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { bezierCurve } from 'styles/tools/constants'

const LINE_HEIGHT = 16
const SECONDS_PER_PRODUCT = 2
// 0 < x < 1
const PROPORTION_TRANSITIONING = 0.5

const Root = styled.div`
  overflow: hidden;
  max-height: ${LINE_HEIGHT}px;
`

const frames = (count: number) => {
  let styles = ''
  const gap = 100 / count * PROPORTION_TRANSITIONING

  for (let i = 0; i < count; i++) {
    const start = i / count * 100

    styles += `
      ${start}% {
        transform: translateY(-${(i + 0.5) * LINE_HEIGHT * 2}px);
      }

      ${start + gap}% {
        transform: translateY(-${(i + 0.5) * LINE_HEIGHT * 2}px);
      }
    `
  }

  const afterHalfOfLastAnimation = 100 - 100 / count / 4 / 2

  styles += `
    ${afterHalfOfLastAnimation}% {
      transform: translateY(-${count * LINE_HEIGHT * 2}px);
    }

    ${afterHalfOfLastAnimation + 0.001}% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-${rem(LINE_HEIGHT)});
    }
  `
  return css`${styles}`
}

const flip = (count: number) => keyframes`
  ${frames(count)}
`

const Flipper = styled.div<{ $count: number; }>`
  animation: ${props => flip(props.$count)} ${props => props.$count * SECONDS_PER_PRODUCT}s ${bezierCurve} infinite;
`

const ProductLabel = styled(BodyText)`
  margin-top: 16px;
`

function ProductNameFlipper() {
  const searchTabs = useGlobalSearchVerticalTabs()

  const productLabels = useMemo(() =>
    [...searchTabs.values()].map(tab => tab.label)
  , [searchTabs])

  return (
    <Root>
      <Flipper $count={productLabels.length}>
        {productLabels.map(label => (
          <ProductLabel colour="neutral-one" variant="small" weight="bold" key={label}>
            {label}
          </ProductLabel>
        ))}
      </Flipper>
    </Root>
  )
}

export default ProductNameFlipper
