import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineStopwatchIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="m18.3 8.59.91-.9a1.0041 1.0041 0 1 0-1.42-1.42l-.9.91a7.9997 7.9997 0 0 0-9.79 0l-.91-.92a1.0077 1.0077 0 0 0-1.42 1.43l.92.91A7.9199 7.9199 0 0 0 4 13.5a8.0004 8.0004 0 0 0 3.3432 6.5254 8.0007 8.0007 0 0 0 10.4332-.9702 8.0008 8.0008 0 0 0 2.0826-7.0301A7.9996 7.9996 0 0 0 18.3 8.59ZM12 19.5a5.9998 5.9998 0 0 1-5.8847-7.1705 6.0002 6.0002 0 0 1 8.1808-4.3728A5.9998 5.9998 0 0 1 12 19.5Zm-2-15h4a1 1 0 1 0 0-2h-4a1 1 0 0 0 0 2Zm3 6a.9998.9998 0 0 0-1-1 1.0001 1.0001 0 0 0-1 1v1.89a1.5004 1.5004 0 0 0-.4012 1.6533A1.5 1.5 0 1 0 13 12.39V10.5Z"/>
  </SvgIcon>
}

export default LineStopwatchIcon
