import AccountAccessModal from 'components/Account/AccountAccess/AccountAccessModal'
import AvatarCircle from 'components/Common/AvatarCircle/AvatarCircle'
import { SvgIconColour } from 'components/Luxkit/SvgIcon'
import ModalContext from 'contexts/ModalContext'
import React, { ComponentProps, useCallback, useContext } from 'react'
import { connect } from 'react-redux'
import { getNotificationType, hasAccountNotification } from 'selectors/accountSelectors'

type InheritedAvatarCircleProps = Pick<ComponentProps<typeof AvatarCircle>, 'size' | 'className'>

interface Props extends InheritedAvatarCircleProps {
  firstName?: string;
  lastName?: string;
  hasNotification: boolean;
  loggedIn?: boolean;
  notificationType: SvgIconColour
}

function HeaderUserAvatar(props: Props) {
  const { hasNotification, firstName, lastName, loggedIn, notificationType, ...rest } = props
  // connect is providing dispatch, we don't want to pass it on and i can't find any type info for it
  delete (rest as any).dispatch

  const showModal = useContext(ModalContext)
  const showLogin = useCallback(() => {
    showModal(<AccountAccessModal />)
  }, [showModal])

  return <AvatarCircle
    {...rest}
    hasNotification={hasNotification}
    firstName={firstName}
    lastName={lastName}
    onClick={loggedIn ? undefined : showLogin}
    role="menuitem"
    to={loggedIn ? '/account' : undefined}
    aria-label={loggedIn ? 'Log in' : 'Account'}
    notificationType={notificationType}
    showRedDot={!loggedIn}
  />
}

function mapStateToProps(state: App.State) {
  return {
    hasNotification: hasAccountNotification(state),
    firstName: state.auth.account.givenName,
    lastName: state.auth.account.surname,
    loggedIn: !!state.auth.account.memberId,
    notificationType: getNotificationType(state),
  }
}

export default connect(mapStateToProps)(HeaderUserAvatar)
