import React, { useContext } from 'react'
import { connect } from 'react-redux'
import BodyText from 'components/Luxkit/Typography/BodyText'
import styled from 'styled-components'
import GeoContext from 'contexts/geoContext'
import Group from 'components/utils/Group'
import { getSVGImageUrl } from 'lib/image/imageUtils'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import { selectLoggedIn } from 'selectors/accountSelectors'
import config from 'constants/config'
import GoogleLongLogo from 'components/Common/TrustIndicators/GoogleLongLogo'
import { TrustPilotLogo } from 'components/Common/TrustIndicators/TrustPilotLogo'

const AtolProtectedContainer = styled.div`
  display: none;
  gap: ${rem(4)};

  ${mediaQueryUp.tablet} {
    display: flex;
  }
`

interface Props {
  loggedIn: boolean;
}

function NavigationHeaderTrustIndicators({ loggedIn }: Props) {
  const { currentRegionCode } = useContext(GeoContext)
  const showTrustIndicators = config.BRAND === 'luxuryescapes' && !loggedIn

  return (
    <Group direction="horizontal" gap={12} verticalAlign="center">
      {currentRegionCode === 'GB' && (
        <AtolProtectedContainer>
          <img src={getSVGImageUrl('984lj4elpskyg828z90qb')} width="16" height="16" alt=""/>
          <BodyText variant="small" weight="bold" wrap="no-wrap">ATOL protected</BodyText>
        </AtolProtectedContainer>
      )}
      {showTrustIndicators && (
        <CSSBreakpoint min="tablet">
          <Group direction="horizontal" gap={12}>
            <Group gap={8} direction="horizontal" verticalAlign="center">
              <GoogleLongLogo width="54" height="18"/>
              <BodyText variant="small" weight="bold">4.7/5</BodyText>
              <CSSBreakpoint min="desktop">
                <BodyText variant="small" colour="neutral-three" wrap="no-wrap">(2.6k reviews)</BodyText>
              </CSSBreakpoint>
            </Group>
            <Group gap={8} direction="horizontal" verticalAlign="center">
              <div>
                <TrustPilotLogo width="74" height="18"/>
              </div>
              <BodyText variant="small" weight="bold" wrap="no-wrap">4.8/5</BodyText>
              <CSSBreakpoint min="desktop">
                <BodyText variant="small" colour="neutral-three" align="justify" wrap="no-wrap">(8k reviews)</BodyText>
              </CSSBreakpoint>
            </Group>
          </Group>
        </CSSBreakpoint>
      )}
    </Group>

  )
}

function mapStateToProps(state: App.State) {
  return {
    loggedIn: selectLoggedIn(state),
  }
}

export default connect(mapStateToProps)(NavigationHeaderTrustIndicators)
