import IconButton from 'components/Luxkit/Button/IconButton'
import LinePhoneIcon from 'components/Luxkit/Icons/line/LinePhoneIcon'
import ModalContext from 'contexts/ModalContext'
import React, { ComponentProps, useCallback, useContext } from 'react'
import HeaderContactOptionsModal from './HeaderContactOptionsModal'

type InheritedIconButtonProps = Pick<ComponentProps<typeof IconButton>, 'size' | 'className'>

interface Props extends InheritedIconButtonProps {
}

function HeaderContactOptionsMenu(props: Props) {
  const showModal = useContext(ModalContext)

  const openModal = useCallback(() => {
    showModal(<HeaderContactOptionsModal />)
  }, [showModal])

  return <IconButton
    {...props}
    kind="tertiary"
    onClick={openModal}
    variant="dark"
    aria-label="Need Help? Call our Travel Experts"
    rel="nofollow"
  >
    <LinePhoneIcon />
  </IconButton>
}

export default HeaderContactOptionsMenu
