import React, { useCallback, useContext } from 'react'
import BusinessTravellerAccountMenuContent from './BusinessTravellerAccountMenuContent'
import Modal from 'components/Luxkit/Modal/Modal'
import ListItem from 'components/Luxkit/List/ListItem'
import ModalContext from 'contexts/ModalContext'
import BusinessAccountModeIcon from './BusinessAccountModeIcon'

interface Props {
  className?: string
}

function BusinessTravellerAccountListItemMenu(props: Props) {
  const { className } = props
  const showModal = useContext(ModalContext)
  const toggleModal = useCallback(() => {
    showModal(<Modal title="Your account">
      <BusinessTravellerAccountMenuContent />
    </Modal>)
  }, [showModal])

  return <ListItem
    className={className}
    title="Account"
    startIcon={<BusinessAccountModeIcon />}
    onClick={toggleModal}
  />
}

export default BusinessTravellerAccountListItemMenu
