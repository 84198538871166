import React, { useContext, useRef } from 'react'
import styled from 'styled-components'
import HeaderBar from './HeaderBar'
import zIndex from 'styles/tools/z-index'
import { mediaQueryUp } from 'components/utils/breakpoint'
import cn from 'clsx'
import useScrollDirection from 'hooks/useScrollDirection'
import { HeaderHeightContext } from 'contexts/HeaderStateContext'
import { useIsMobileScreen, useScreenSizeOnly } from 'hooks/useScreenSize'
import { useInView } from 'react-intersection-observer'
import ScrollAnchor from 'components/Common/ScrollAnchor'
import { rem } from 'polished'
import { useAppHeaderObserver } from 'hooks/useAppLayoutObservers'

const HeaderBarStyled = styled(HeaderBar)`
  position: sticky;
  top: var(--top-offset, 0);
  transform: translateY(0);
  transition: transform 0.2s, box-shadow 0.2s;
  z-index: ${zIndex.stickyHeader};

  &.shy {
    box-shadow: ${(props) => props.theme.shadow.bottom.small};

    &.mobile-hidden {
      transform: translateY(-100%);
    }
  }

  ${mediaQueryUp.tablet} {
    top: 0;

    &.shy.mobile-hidden {
      transform: unset;
    }
  }
`

function StickyHeaderBar() {
  const watchDirection = useIsMobileScreen()
  const direction = useScrollDirection({ tolerance: 10, disabled: !watchDirection })
  const { heights } = useContext(HeaderHeightContext)
  const [anchorRef, anchorInView] = useInView({ initialInView: true })
  const containerRef = useRef<HTMLDivElement>(null)
  const isMobileScreenOnly = useScreenSizeOnly('mobile')
  useAppHeaderObserver(
    containerRef,
    isMobileScreenOnly ? ((direction === 'up' && !anchorInView) ? 'sticky' : 'static') : 'sticky',
  )

  return <>
    <HeaderBarStyled
      ref={containerRef}
      className={cn({
        // anchor isn't in view anymore, that means we've scrolled past the header and we now want to
        // start thinking about it as a 'shy' header
        shy: !anchorInView,
        'mobile-hidden': direction === 'down',
      })}
      style={{ ['--top-offset' as string]: `${heights.siteStickyBanner}px` }}
    />
    <ScrollAnchor vOffset={rem(heights.siteStickyBanner)} ref={anchorRef} />
  </>
}

export default StickyHeaderBar
