import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { isSupportAssistantEnabled } from 'selectors/featuresSelectors'
import config from 'constants/config'
import LineCommentsIcon from 'components/Luxkit/Icons/line/LineCommentsIcon'
import LinePhoneIcon from 'components/Luxkit/Icons/line/LinePhoneIcon'
import ListItem from 'components/Luxkit/List/ListItem'
import GeoContext from 'contexts/geoContext'
import { useRenderWhatsApp } from 'hooks/useRenderWhatsApp'
import ContactContext from 'contexts/contactContext'
import { getSVGImageUrl } from 'lib/image/imageUtils'

interface Props {
  supportAssistantEnabled: boolean;
  className?: string;
  onClick: () => void;
}

function PhoneNumberMobileMenu({
  supportAssistantEnabled,
  onClick,
  className,
}: Props) {
  const currentContact = useContext(ContactContext)
  const localContact = currentContact.local
  const internationalContact = currentContact.international
  const { currentRegionCode } = useContext(GeoContext)
  const shouldRenderWhatsApp = useRenderWhatsApp(currentRegionCode)

  return (
    <div className={className}>
      <ListItem
        title="Local Sales & Support"
        subtitle={localContact.humanReadable}
        startIcon={<LinePhoneIcon />}
        href={`tel:${localContact.number}`}
        aria-label="Local Sales & Support contact"
        rel="nofollow"
      />
      {supportAssistantEnabled && <ListItem
        title="Chat now"
        subtitle="Luxury Escapes Support Assistant"
        startIcon={<LineCommentsIcon />}
        to="/support"
        onClick={onClick}
        aria-label="Luxury Escapes Support Assistant"
        rel="nofollow"
      />}
      {shouldRenderWhatsApp &&
        <ListItem
          title="WhatsApp"
          startIcon={<img src={getSVGImageUrl('whatsapp-logo')} width="24" height="24" alt="WhatsApp"/>}
          href={`https://wa.me/${config.WHATS_APP_NUMBER}`}
          aria-label="Whats App"
          rel="noopener"
          target="_blank"
        />}
      <ListItem
        title="International Support"
        startIcon={config.BRAND === 'luxuryescapes' ? <LinePhoneIcon /> : undefined}
        subtitle={internationalContact.humanReadable}
        href={`tel:${internationalContact.number}`}
        aria-label="International Support contact"
        rel="nofollow"
      />
    </div>
  )
}

function mapStateToProps(state: App.State) {
  return {
    supportAssistantEnabled: isSupportAssistantEnabled(state),
  }
}

export default connect(mapStateToProps)(PhoneNumberMobileMenu)
