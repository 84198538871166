import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineBarsIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M3 8h18a1 1 0 1 0 0-2H3a1 1 0 0 0 0 2Zm18 8H3a1.0002 1.0002 0 0 0-.7071 1.7071C2.4804 17.8946 2.7348 18 3 18h18a1.0001 1.0001 0 0 0 0-2Zm0-5H3a1.0002 1.0002 0 0 0-.7071 1.7071C2.4804 12.8946 2.7348 13 3 13h18a1.0001 1.0001 0 0 0 0-2Z"/>
  </SvgIcon>
}

export default LineBarsIcon
