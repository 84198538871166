import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineBoltAltIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M19.89 9.55A1.0001 1.0001 0 0 0 19 9h-5V3a1.0002 1.0002 0 0 0-.69-1 1.0002 1.0002 0 0 0-1.12.36l-8 11a1.0004 1.0004 0 0 0-.08 1A1 1 0 0 0 5 15h5v6a1.0003 1.0003 0 0 0 .69.95c.1005.0312.2048.048.31.05a.9995.9995 0 0 0 .81-.41l8-11a1 1 0 0 0 .08-1.04ZM12 17.92V14a1.0003 1.0003 0 0 0-1-1H7l5-6.92V10a.9997.9997 0 0 0 1 1h4l-5 6.92Z"/>
  </SvgIcon>
}

export default LineBoltAltIcon
