import throttle from 'lib/function/throttle'
import { useEffect, useRef, useState } from 'react'

interface Params {
  tolerance?: number
  disabled?: boolean;
}

function useScrollDirection(params: Params = {}): 'up' | 'down' {
  const { disabled, tolerance = 0 } = params
  const toleranceRef = useRef<number>(tolerance)

  useEffect(() => {
    toleranceRef.current = tolerance
  }, [tolerance])

  const [direction, setDirection] = useState<'up' | 'down'>('down')
  const directionRef = useRef<'up' | 'down'>('down')
  const windowTop = useRef(0)

  useEffect(() => {
    if (!disabled) {
      const onScroll = () => {
        window.requestAnimationFrame(() => {
          const tolerance = toleranceRef.current
          const windowBottom = windowTop.current + tolerance

          // Prevent mobile Safari's overscroll bounce from triggering the opposite direction
          //
          // actually I've not fixed the same problem at the bottom but we can get to that if we
          // can get to that if we need to. It's quite expensive to continuously check the height
          // of the client

          const realPageYOffset = Math.max(0, window.scrollY)
          if (realPageYOffset > windowBottom) {
            windowTop.current = realPageYOffset - tolerance
            if (directionRef.current === 'up') {
              directionRef.current = 'down'
              setDirection('down')
            }
          }
          else if (realPageYOffset < windowTop.current) {
            windowTop.current = realPageYOffset
            if (directionRef.current === 'down') {
              directionRef.current = 'up'
              setDirection('up')
            }
          }
        })
      }

      const throttleScroll = throttle(onScroll, 300, { leading: true })

      window.addEventListener('scroll', throttleScroll, { passive: true })
      window.addEventListener('touchmove', throttleScroll, { passive: true })
      window.addEventListener('scrollend', onScroll, { passive: true })

      return () => {
        window.removeEventListener('scroll', throttleScroll)
        window.removeEventListener('touchmove', throttleScroll)
        window.addEventListener('scrollend', onScroll)
      }
    }
  }, [disabled])

  return direction
}

export default useScrollDirection
