import Flag from 'components/Luxkit/Flag'
import ListItem from 'components/Luxkit/List/ListItem'
import React, { MouseEvent, MouseEventHandler, useCallback } from 'react'

interface Props {
  region: App.RegionSummary
  onRegionClick: (region: App.RegionSummary, event: MouseEvent<HTMLButtonElement>) => void
}

function RegionListItem(props: Props) {
  const { region, onRegionClick } = props

  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    onRegionClick(region, event)
  }, [onRegionClick, region])

  return <ListItem
    data-regioncode={region.code}
    aria-label={`Select ${region.name} region`}
    size="medium"
    startIcon={<Flag countryCode={region.code} />}
    title={region.name}
    onClick={handleClick}
  />
}

export default RegionListItem
