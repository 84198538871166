// Taken from stack overflow: https://stackoverflow.com/questions/27078285/simple-throttle-in-js

// Returns a function, that, when invoked, will only be triggered at most once
// during a given window of time. Normally, the throttled function will run
// as much as it can, without ever going more than once per `wait` duration;
// but if you'd like to disable the execution on the leading edge, pass
// `{leading: false}`. To disable execution on the trailing edge, ditto.
function throttle(
  func: Function,
  wait: number,
  options: { leading?: boolean, trailing?: boolean } = {},
) {
  let context: any, args: any, result: any
  let timeout: number | null = null
  let previous = 0
  const later = function() {
    previous = !options.leading ? 0 : Date.now()
    timeout = null
    result = func.apply(context, args)
    if (!timeout) context = args = null
  }
  return function() {
    const now = Date.now()
    if (!previous && !options.leading) previous = now
    const remaining = wait - (now - previous)
    // @ts-ignore This is a lib function, doesn't really need ts checking
    context = this
    args = arguments
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        window.clearTimeout(timeout)
        timeout = null
      }
      previous = now
      result = func.apply(context, args)
      if (!timeout) context = args = null
    } else if (!timeout && options.trailing) {
      timeout = window.setTimeout(later, remaining)
    }
    return result
  }
}

export default throttle
