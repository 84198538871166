import ContactOptions from 'components/Common/ContactOptions'
import LayoutContainer from 'components/Common/LayoutContainer'
import { rem } from 'polished'
import React, { useRef } from 'react'
import { connect } from 'react-redux'
import { isFlightsCredit } from 'selectors/flightsSelectors'
import styled from 'styled-components'
import NavigationHeaderRegionMenu from './NavigationHeaderRegionMenu'
import NavigationHeaderTrustIndicators from './NavigationHeaderTrustIndicators'
import Group from 'components/utils/Group'
import config from 'constants/config'
import { useAppHeaderObserver } from 'hooks/useAppLayoutObservers'

const Root = styled.div`
  background-color: ${props => props.theme.palette.neutral.default.seven};
  width: 100%;
`

const Container = styled(LayoutContainer)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: ${rem(2)} 0;
`

interface Props {
  isFlightsCredit: boolean;
}

function NavigationTertiaryHeader(props: Props) {
  const containerRef = useRef<HTMLDivElement>(null)
  useAppHeaderObserver(containerRef)

  return (
    <Root ref={containerRef} className="no-print">
      <Container>
        <Group direction="horizontal" gap={12}>
          <NavigationHeaderTrustIndicators />
          <ContactOptions />
        </Group>
        {!props.isFlightsCredit && !config.agentHub.isEnabled && <NavigationHeaderRegionMenu />}
      </Container>
    </Root>
  )
}

function mapState(state: App.State) {
  return {
    isFlightsCredit: isFlightsCredit(state),
  }
}

export default connect(mapState)(NavigationTertiaryHeader)
